import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const CenterAdminSignIn = () => {
  useEffect(() => {
    navigate(`/dashboard`, { replace: true });
  }, []);

  return null;
};

export default CenterAdminSignIn;
